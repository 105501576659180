const parentImpurity = [
    {
        "name": "Alogliptine",
        "firstChar":"A"
    },
    {
        "name": "Apixaban",
        "firstChar":"A"
    },
    {
        "name": "Aspirin",
        "firstChar":"A"
    },
    {
        "name": "Azelastine",
        "firstChar":"A"
    },
    {
        "name": "Azilsartan",
        "firstChar":"A"
    },
    {
        "name": "Benzoyl Peroxide",
        "firstChar":"B"
    },
    {
        "name": "Bosenton",
        "firstChar":"B"
    },{
        "name": "Bupropion",
        "firstChar":"B"
    },{
        "name": "Bisoprolol",
        "firstChar":"B"
    },
    {
        "name": "Alogliptine",
        "firstChar":"A"
    },
    {
        "name": "Apixaban",
        "firstChar":"A"
    },
    {
        "name": "Aspirin",
        "firstChar":"A"
    },
    {
        "name": "Azelastine",
        "firstChar":"A"
    },
    {
        "name": "Azilsartan",
        "firstChar":"A"
    },
    {
        "name": "Benzoyl Peroxide",
        "firstChar":"B"
    },
    {
        "name": "Bosenton",
        "firstChar":"B"
    },{
        "name": "Bupropion",
        "firstChar":"B"
    },{
        "name": "Bisoprolol",
        "firstChar":"B"
    },{
        "name": "Alogliptine",
        "firstChar":"A"
    },
    {
        "name": "Apixaban",
        "firstChar":"A"
    },
    {
        "name": "Aspirin",
        "firstChar":"A"
    },
    {
        "name": "Azelastine",
        "firstChar":"A"
    },
    {
        "name": "Azilsartan",
        "firstChar":"A"
    },
    {
        "name": "Benzoyl Peroxide",
        "firstChar":"B"
    },
    {
        "name": "Bosenton",
        "firstChar":"B"
    },{
        "name": "Bupropion",
        "firstChar":"B"
    },{
        "name": "Bisoprolol",
        "firstChar":"B"
    },{
        "name": "Alogliptine",
        "firstChar":"A"
    },
    {
        "name": "Apixaban",
        "firstChar":"A"
    },
    {
        "name": "Aspirin",
        "firstChar":"A"
    },
    {
        "name": "Azelastine",
        "firstChar":"A"
    },
    {
        "name": "Azilsartan",
        "firstChar":"A"
    },
    {
        "name": "Benzoyl Peroxide",
        "firstChar":"B"
    },
    {
        "name": "Bosenton",
        "firstChar":"B"
    },{
        "name": "Bupropion",
        "firstChar":"B"
    },{
        "name": "Bisoprolol",
        "firstChar":"B"
    }

]
export default parentImpurity;