// src/components/About.js
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


function About() {
  return (
    <>
    <Header></Header>
    <Footer></Footer>
    </>
  );
}

export default About;
